import React, { Fragment } from 'react'

import Helmet from 'react-helmet'

import styled from 'styled-components'

import root from 'utils/windowOrGlobal'

import config from 'config'

import Icon from 'components/Icon'

const Logo = styled(Icon).attrs({ name: 'sc-logo', id: 'logo' })`
  color: #fff;
  height: 50px;
`

const page = {
  title: 'StayCircles Mobile - Search, Book, Stay with Friends',
  description:
    'StayCircles makes it easy to search, book and stay with friends as you travel for business or fun. Get the official StayCircles mobile app now.',
  logo:
    'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077900/assets/meta/Logo_White.png'
}

const ga = (...args) => root && root.ga && root.ga(...args)

const DownloadStayCirclesAppPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{page.title}</title>
        <meta name='description' content={page.description} />
        <meta name='image' content={page.logo} />
        <meta itemProp='name' content={page.title} />
        <meta itemProp='description' content={page.description} />
        <meta itemProp='image' content={page.logo} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={page.title} />
        <meta name='twitter:description' content={page.description} />
        <meta name='og:title' content={page.title} />
        <meta name='og:description' content={page.description} />
        <meta
          name='og:image'
          content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542078118/assets/meta/Home.png'
        />
        <meta name='og:url' content='https://www.staycircles.com/downloadapp' />
        <meta name='og:site_name' content='StayCircles' />
        <meta name='og:type' content='website' />

        {config.ENV !== 'development' && (
          <script src='https://cdn.logrocket.com/LogRocket.min.js' />
        )}
        {config.ENV !== 'development' && (
          <script
            type='text/javascript'
            dangerouslySetInnerHTML={{
              __html: `
              window.LogRocket && window.LogRocket.init('staycircles/download-app${
          config.ENV === 'staging' ? '-staging' : ''
          }');
            `
            }}
          />
        )}

        <link
          href='//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/bootstrap.min.css'
          rel='stylesheet'
          id='bootstrap-css'
        />

        <style type='text/css'>{`
          @import url("https://fonts.googleapis.com/css?family=Lato:400,600");

          html {
            overflow-y: auto;
          }

          body {
            height: 100%;
            width: 100%;
            position: relative;
            font-family: 'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
            color: white;
          }

          .item h1,
          .item h3 {
            font-weight: 600;
          }

          #logo {
            position: absolute;
            top: 20px;
            left: 20px;
            height: 50px;
          }

          #signin {
            position: absolute;
            top: 20px;
            right: 20px;
            color: white;
            font-size: 18px;
          }

          #background-carousel {
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: #070707;
          }

          .carousel,
          .carousel-inner {
            width: 100%;
            height: 100%;
            z-index: 0;
            overflow: hidden;
          }

          .item {
            width: 100%;
            height: 100%;
            z-index: 0;
            background-position: center center;
            background-size: cover;
          }

          .text {
            display: flex;
            padding: 50px;
            width: 50vw;
            margin-left: 50vw;
            height: 100%;
            background: linear-gradient(270deg,rgba(0, 0, 0, 0.35) 0%,rgba(0, 0, 0, 0.05) 95%,rgba(0, 0, 0, 0) 100%);
            flex-direction: column;
            justify-content: center;
          }

          .item h1 {
            font-size: 48px;
          }

          .item h3 {
            font-fize: 32px;
          }

          .item img.hand {
            position: absolute;
            height: 80vh;
            right: 50vw;
            bottom: 0;
            margin-bottom: 0;
          }

          .item .logo {
            margin-top: 20px;
            cursor: pointer;
            width: 200px;
            margin-right: 20px;
          }

          @media (max-width: 400px) {
            .item h1 {
              font-size: 28px;
            }
            .item h3 {
              font-size: 18px;
            }
            .store-logos {
              flex-direction: row;
              justify-content: space-around;
            }
            .item .logo {
              width: 120px;
            }
          }

          @media (max-width: 568px) {
            .text {
              top: 0;
              width: 100%;
              margin-left: 0;
              padding: 0 25px;
              height: 75vh;
              background: linear-gradient(180deg,rgba(0, 0, 0, 0.35) 0%,rgba(0, 0, 0, 0.05) 90%,rgba(0, 0, 0, 0) 100%);
            }
            .item img.hand {
              height: 29vh;
              right: 35vw;
            }
            .store-logos {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }

          @media (min-width: 768px) and (max-width: 1025px) {
            .item img.hand {
              right: 45vw;
              height: 75vh;
            }
          }


          /* Bootstrap Carousel fade */
          .carousel-fade .carousel-inner .item {
            opacity: 0;
            transition-property: opacity;
          }

          .carousel-fade .carousel-inner .active {
            opacity: 1;
          }

          .carousel-fade .carousel-inner .active.left,
          .carousel-fade .carousel-inner .active.right {
            left: 0;
            opacity: 0;
            z-index: 1;
          }

          .carousel-fade .carousel-inner .next.left,
          .carousel-fade .carousel-inner .prev.right {
            opacity: 1;
          }

          .carousel-fade .carousel-control {
            z-index: 2;
          }

          @media all and (transform-3d), (-webkit-transform-3d) {
            .carousel-fade .carousel-inner > .item.next,
            .carousel-fade .carousel-inner > .item.active.right {
              opacity: 0;
              -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
            }
            .carousel-fade .carousel-inner > .item.prev,
            .carousel-fade .carousel-inner > .item.active.left {
              opacity: 0;
              -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
            }
            .carousel-fade .carousel-inner > .item.next.left,
            .carousel-fade .carousel-inner > .item.prev.right,
            .carousel-fade .carousel-inner > .item.active {
              opacity: 1;
              -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
            }
          }

          @media screen and (orientation: landscape) and (max-width: 824px) and (max-height: 415px), screen and (max-width: 600px) {
            .store-logos {
              flex-direction: row;
              justify-content: space-around;
            }
            .item h1 {
              font-size: 28px;
            }
            .item h3 {
              font-size: 18px;
            }
            .item .logo {
              width: 120px;
            }
            #logo {
              top: 5px;
              left: 5px;
              height: 30px;
            }
            #signin {
              top: 5px;
              right: 8px;
              font-size: 16px;
            }
          }

          @media screen and (orientation: landscape) and (max-width: 824px) and (max-height: 415px) {
            .text {
              width: 70vw;
              margin-left: 30vw;
              padding: 10px;
            }
            .item img.hand {
              right: 70vw;
            }
          }

        `}</style>
      </Helmet>

      <a href='//staycircles.com' target='_blank'>
        <Logo />
      </a>

      <a href='//www.staycircles.com?login=true' target='_blank'>
        <span id='signin'>Log in</span>
      </a>

      <div id='background-carousel'>
        <div
          className='myCarousel carousel slide carousel-fade'
          data-ride='carousel-1'
        >
          <div className='carousel-inner'>
            <div
              className='item active'
              style={{
                backgroundImage:
                  'url(https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1529056850/assets/applanding/nyc_pins_bg.jpg)'
              }}
            >
              <img
                className='hand'
                src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1528992782/assets/applanding/nyc_hand.png'
              />
              <div className='text'>
                <h1>
                  Travel for work, stay with friends.
                  <br />
                  Earn for you, save for your company.
                </h1>
                <h3>Business travel where everyone wins.</h3>
                <div className='store-logos'>
                  <a
                    target='_blank'
                    href='https://itunes.apple.com/us/app/staycircles/id1402291438?ls=1&mt=8'
                    onClick={ga(
                      'send',
                      'event',
                      'SC App Store Link',
                      'open',
                      'iOS App Store'
                    )}
                  >
                    <img
                      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1529001776/assets/applanding/app-store-logo.png'
                      className='logo'
                    />
                  </a>
                  <a
                    target='_blank'
                    href='https://play.google.com/store/apps/details?id=com.staycircles.android'
                    onClick={ga(
                      'send',
                      'event',
                      'SC App Store Link',
                      'open',
                      'Android Play Store'
                    )}
                  >
                    <img
                      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1529001776/assets/applanding/play-store-logo.png'
                      className='logo'
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className='item'
              style={{
                backgroundImage:
                  'url(https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1529055774/assets/applanding/rio_pins_bg.jpg)'
              }}
            >
              <img
                className='hand'
                src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1528992782/assets/applanding/rio_hand.png'
              />
              <div className='text'>
                <h1>
                  Search, visit, and have fun.
                  <br /> Stay with each other.
                </h1>
                <h3>
                  Find and stay with friends or friends-of-friends across the
                  world on your StayCircles map, for free.
                </h3>
                <div className='store-logos'>
                  <a
                    target='_blank'
                    href='https://itunes.apple.com/us/app/staycircles/id1402291438?ls=1&mt=8'
                    onClick={ga(
                      'send',
                      'event',
                      'SC App Store Link',
                      'open',
                      'iOS App Store'
                    )}
                  >
                    <img
                      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1529001776/assets/applanding/app-store-logo.png'
                      className='logo'
                    />
                  </a>
                  <a
                    target='_blank'
                    href='https://play.google.com/store/apps/details?id=com.staycircles.android'
                    onClick={ga(
                      'send',
                      'event',
                      'SC App Store Link',
                      'open',
                      'Android Play Store'
                    )}
                  >
                    <img
                      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1529001776/assets/applanding/play-store-logo.png'
                      className='logo'
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script
        type='text/javascript'
        src='//code.jquery.com/jquery-1.11.1.min.js'
      />
      <script
        type='text/javascript'
        src='//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/js/bootstrap.min.js'
      />
      {renderCarouselStarter()}
    </Fragment>
  )
}

function renderCarouselStarter () {
  return (
    <script
      type='text/javascript'
      dangerouslySetInnerHTML={{
        __html: `
          $('.myCarousel').carousel({
            pause: 'none',
            interval: 10000
          });

          $(function () {
            document.addEventListener("touchmove", function(e){ e.preventDefault(); }, false);
          });
        `
      }}
    />
  )
}

export default DownloadStayCirclesAppPage
